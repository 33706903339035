<!--放映员-我要点播-->

<template>
  <div class="demandList">
    <div class="filter">
      <!-- 历史记录 -->
      <van-row gutter="20">
        <van-col @click="$refs.selectAreaPoint.open()" :span="18"
          >{{ showPoint ? showPoint.name : '请选择放映点' }}
          <van-icon name="arrow-down" color="#007aff" />
        </van-col>
        <!-- 历史点播记录 -->
        <van-col :span="6"  >
          <span @click="$router.push('/me/playRecordHistory')" style="color: #1989fa; font-size: 14px">点播记录</span>
        </van-col>
      </van-row>
    </div>
    <van-calendar
      :show-title="false"
      :poppable="false"
      :show-confirm="false"
      :max-date="maxDate"
      :style="{ height: '350px' }"
      :row-height="40"
      :default-date="null"
      color="#d5ebe1"
      :formatter="formatter"
      first-day-of-week="1"
      @select="handleDateSelect"
    />
    <div class="filter">
      <!-- 筛选状态 -->
      <select class="select" v-model="filterStatus">
        <option value="">所有点播</option>
        <option value="0">待审核</option>
        <option value="2">已通过</option>
      </select>
<!--      放映员-->
      <!-- <select class="select" v-model="screeners">
        <option :value="mitter" v-for="mitter in screenersList" :key="mitter">
          {{ mitter}}
        </option>
      </select> -->
      <!-- <van-button type="default" plain icon="filter-o"> 筛选</van-button> -->
      <!-- 历史记录 -->
    </div>
    <div class="list">
      <div class="list-item" v-for="(day, index) in filterScreeners(demandList,screeners)" :key="index">
<!--        <pre>-->
<!--          {{day}}-->
<!--        </pre>-->
        <div class="list-item-header">
          <div class="list-item-header-left">
            <div
              class="list-item-header-left-status"
              :style="{ color: statusColor(day.status) }"
            >
              <span
                class="status-mark"
                :style="{ backgroundColor: statusColor(day.status) }"
              ></span>
              <span v-if="day.status === 0"> 待审核</span>
              <span v-if="day.status === 2"> 已通过</span>
              <span v-if="day.status === 3"> 已取消</span>
            </div>
          </div>
          <div class="list-item-header-right">
            <span
              style="color: #f5a623"
              @click="cancelDemand(day)"
              v-if="day.status === 1 || day.status === 0"
            >
              撤销
              <van-icon name="revoke" />
            </span>
            <router-link
              :to="{ path: 'demandDetail', params: { id: day.id } }"
              v-if="day.status === -1"
            >
              查看
              <van-icon name="eye" color="#007aff" />
            </router-link>
          </div>
        </div>
        <div class="list-item-body">
          <p>
            {{ day.Date }} {{ new Date(day.Date) | formatWeek }}
            {{ day.playStartTime }}
          </p>
          <!-- 放映影片 -->

          <div>
            放映影片：{{ day.films.map((film) => film.name).join('/') }}
          </div>
<!--          <div>放映地点：{{ day.showPoint.name }}({{ day.playPlace }})</div>-->
          <div>放映地点：{{ day.playPlace }}</div>
          <div>放映员：{{ day.submitter }}</div>
        </div>
      </div>
      <div v-if="demandList.length === 0">
        <van-empty
          description="暂无数据"
          :image-size="100"
          :image-color="['#f5a623', '#E63633', '#52c41a']"
        ></van-empty>
      </div>
    </div>
    <select-area-point
      ref="selectAreaPoint"
      @onFinish="handleFinish"
    ></select-area-point>
  </div>
</template>

<script>
import { api } from '@/api/index'
import { Dialog, Toast } from 'vant'
import selectAreaPoint from './modules/selectAreaPoint'

export default {
  name: 'index',
  components: {
    selectAreaPoint,
  },
  data() {
    return {
      active: '',
      filterStatus: '',
      selectDate: new Date(),
      maxDate: this.moment().add(1, 'month')._d, // 当前时间+1月
      historyFinished: false,
      historyLoading: false,
      // 日历点播数列表
      calendarList: [],
      // 点播列表
      demandList: [],
      demandHistoryList: [],
      historyPageIndex: 1,
      historyPageSize: 10,
      showPoint: null,
      // 放映员
      screeners: '不限',
      // 放映员列表
      screenersList: [],
    }
  },
  mounted() {
    this.active = this.$route.query.active || 0
    this.showPoint = this.$store.state.projectorPoint
    
    if (!this.showPoint) {
      this.$refs.selectAreaPoint.open()
    } else {
      if(!this.showPoint.id){
        this.$refs.selectAreaPoint.open()
      }
      this.getCalendarList()
    }
  },
  methods: {
    handleFinish(data) {
      console.log({data});
      
      if (data.id === 0) {
        // 选择了全部
        this.showPoint = {
          name: '全部放映点',
          id: null,
        } 
        this.$store.commit('changeProjectorPoint',this.showPoint)
      } else {
        this.showPoint = data
        this.$store.commit('changeProjectorPoint', data)
      }
      this.$forceUpdate()
      this.getCalendarList()
      if(!data.id){
        // this.getList()
      }
    },
    // 获取点播日历
    getCalendarList() {
      const params = {
        startDate: this.moment().format('YYYY-MM-DD'),
        endDate: this.moment(this.maxDate).format('YYYY-MM-DD'),
        showPointId: this.showPoint.id,
      }
      api.filmmaker.getScreeningDateList(params).then((res) => {
        const { success, result, message } = res
        if (success) {
          // console.log("AAAAAAAAA", result)
          this.calendarList = result
          this.getList()
        } else {
          Toast.fail(message)
        }
      })
    },
    // 获取放映员点播表-列表
    getList() {
      const params = {
        startDate: this.moment().format('YYYY-MM-DD'),
        endDate: this.moment(this.maxDate).format('YYYY-MM-DD'),
        showPointId: this.showPoint.id,
        status: this.filterStatus || '',
      }
      api.filmmaker
        .getScreeningRecordList(params)
        .then((res) => {
          const { result } = res
          // console.table(result)
          // const TimeGroup = []
          this.demandList=[]
          this.screeners='不限'
          this.demandList = result.map((play) => {
            return {
              ...play,
              id: play.id,
              Date: play.playStartDate,
              playStartTime: play.playStartTime,
              playEndTime: '12:00',
              status: play.status,
              playPlace: play.playPlace,
              films: play.films,
            }
          })
          this.updateScreeners()
          this.$forceUpdate()
        })
        .catch((err) => {
          console.log(err)
        })
    },

    // 更新放映员列表
    updateScreeners() {
      const list=['不限']
      this.demandList.map((item) => {
        if (item.submitter) {
          if (list.indexOf(item.submitter) === -1) {
            list.push(item.submitter)
          }
        }
      })
      this.screenersList=list

    },

    toPage(url, params) {
      this.$router.push({
        path: url,
        query: params,
      })
    },
    formatter(day) {
      // const month = day.date.getMonth() + 1
      // const date = day.date.getDate()

      const dayData = this.calendarList.filter((D) => {
        return D.playDate === this.moment(day.date).format('YYYY-MM-DD')
      }) // 当天播放场次数量
      if (dayData.length > 0 && dayData[0].count > 0) {
        day.className = 'activeDate'
        if (dayData[0].count === 3) {
          day.className = 'activeDate full'
        }
      }

      return day
    },
    // 状态颜色
    statusColor(status) {
      switch (status) {
        case 0:
          return '#f5a623'

        case -1:
          return '#E63633'
        case 2:
          return '#52c41a'
        default:
          return '#909399'
      }
    },
    handleDateSelect(date) {
      console.log({date});
      if(!this.showPoint.id){
        Toast.fail('请选择放映点')
        return
      }
      const dayData = this.calendarList.filter((D) => {
        return D.playDate === this.moment(date).format('YYYY-MM-DD')
      }) // 当天播放场次数量
      const count = dayData.length > 0 ? dayData[0].count : 0
      let message =
        count > 0
          ? `${this.moment(date).format(
              'YYYY-MM-DD',
            )}已有${count}场次，是否预约？`
          : `${this.moment(date).format('YYYY-MM-DD')}暂无放映，是否预约？`
      if (count >= 3) {
        message = `该日期已有${count}场次，不可预约！`
        Toast.fail(message)
        return
      }
      // console.log({point:this.showPoint, date:this.moment(date).format('YYYY-MM-DD')});

      const params = {
        date: this.moment(date).format('YYYY-MM-DD'),
        showPoint: JSON.stringify({
          id: this.showPoint.id,
          name: this.showPoint.name,
          address: this.showPoint.address,
        }),
      }
      console.log(params);

      Dialog.confirm({
        title: '确认预约',
        message,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        confirmButtonColor: '#409eff',
        type: 'warning',
        callback: (action) => {
          if (action === 'confirm') {
            this.toPage('/screenDemand/edit', {
              ...params,
            })
          }
        },
      })
    },
    // 取消点播记录
    cancelDemand(id) {
      Dialog.confirm({
        title: '确认取消',
        message: '是否确认取消点播记录？',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        confirmButtonColor: '#409eff',
        type: 'warning',
        callback: (action) => {
          if (action === 'confirm') {
            api.filmmaker
              .cancelScreeningRecord(id)
              .then((res) => {
                const { success, message } = res
                if (success) {
                  Toast.success('取消成功')
                  this.getList()
                } else {
                  Toast.fail(message)
                }
              })
              .catch((err) => {
                console.log(err)
              })
          }
        },
      })
    },
    // 根据放映员筛选
    filterScreeners(list, screeners) {
      if (screeners === '不限') {
        return list
      }
      return list.filter((item) => item.submitter === screeners)
    },
  },
  filters: {
    // 日期格式化
    formatDate(time) {
      const date = new Date(time)
      // const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${month}-${day}`
    },
    // 日期格式化为星期
    formatWeek(time) {
      const date = new Date(time)
      const week = ['日', '一', '二', '三', '四', '五', '六']
      const weekDay = date.getDay()
      return `星期${week[weekDay]}`
    },

  },
  watch: {
    filterStatus(val) {
      this.getList()
    },
  },
}
</script>

<style scoped lang="less">
.demandList {
  background-color: #f6f7f8;
  min-height: 100vh;
}

.filter {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin: 12px;

  & > .select {
    width: 100px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e6e8eb;
  }
}

.list {
  margin: 12px;

  &-item {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 6px;
    margin-bottom: 8px;
    background-color: #fff;
    border: 1px solid #e8e9ec;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #e8e9ec;

      .status-mark {
        display: inline-block;
        width: 8px;
        height: 14px;
        border-radius: 2px;
      }

      &-left {
      }

      &-right > a {
        color: @color-primary;
      }
    }

    &-body {
      padding: 10px;

      & > p {
        font-weight: bold;
        font-size: 16px;
        color: #363a44;
        line-height: 22px;
        height: 22px;
        text-align: left;
        padding: 0;
        margin: 0;

        font-style: normal;
      }

      & > div {
        color: #686b73;
        padding: 2px;
      }
    }
  }
}

::v-deep .activeDate {
  //border: 1px solid red;
  //width: 10px;
  //height: 10px;
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 10vw;
    height: 10vw;
    background-color: rgba(0, 197, 109, 0.55);
    border-radius: 50%;
    top: 0;
    z-index: 0;
  }
}

::v-deep .full {
  &::after {
    background-color: #bb2612bf;
  }
}
</style>
