<template>
  <div class="page">
    <!--    点播列表  -->
    <!-- <van-nav-bar
      :title="`${recordType === 'personal' ? '个人' : '集体代表'}-点播记录`"
      left-arrow
      @click-left="$router.go(-1)"
    /> -->
    <div class="play-record-tab">
      <!-- 加载完毕- finished:{{ finished }}{{ recordType }} -->
      <van-tabs
        type="line"
        color="#1492FF"
        line-height="0px"
        line-width="0"
        title-class="tab-title"
        swipeable
        background="#EEF0F3"
        animated
      >
        <van-tab>
          <template #title>
            <van-button
              @click="activeIndex = 0"
              style="width: 148px"
              block
              :type="activeIndex == 0 ? 'info' : 'default'"
              size="small"
              :color="activeIndex == 0 ? '#1492FF' : '#B3B5B9'"
              >本周</van-button
            >
          </template>
          <record-list
            :playRecordList="playRecordList"
            v-if="activeIndex == 0"
            :showLoading="showLoading"
            :finished="finished"
            :recordType="recordType"
            @onReachBottom="onReachBottom"
            @loadMore="loadMore"
          />
        </van-tab>
        <van-tab>
          <template #title>
            <van-button
              @click="activeIndex = 1"
              block
              style="width: 148px"
              :color="activeIndex == 1 ? '#1492FF' : '#B3B5B9'"
              :type="activeIndex == 1 ? 'info' : 'default'"
              size="small"
              >全部</van-button
            >
          </template>
          <record-list
            :playRecordList="playRecordList"
            :showLoading="showLoading"
            v-if="activeIndex == 1"
            :finished="finished"
            :recordType="recordType"
            @onReachBottom="onReachBottom"
            @loadMore="loadMore"
          />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { api } from '@/api'
import recordList from './module/recordList'
import rules from '@/router/routerRules'

export default {
  components: { recordList },
  data() {
    return {
      // 点播记录类型
      recordType: 'personal', // personal: 个人点播记录，group: 集体点播记录
      showLoading: false,
      userInfo: null,
      finished: false,
      playRecordList: [],
      activeIndex: 0,
      completed: true,
      total: 0,
      queryParam: {
        pageNo: 0,
        pageSize: 10,
        completed: true,
        startDate: '',
        endDate: '',
      },
    }
  },
  mounted() {
    this.recordType = this.$route.query.type
      ? this.$route.query.type
      : 'personal'
    // 默认开始事件为当周
    this.queryParam.startDate = this.moment()
      .startOf('week')
      .format('YYYY-MM-DD')
    this.queryParam.endDate = this.moment().endOf('week').format('YYYY-MM-DD')
    this.userInfo = this.$store.state.userInfo
    this.onReachBottom()
  },
  methods: {
    // 获取点播记录
    getPlayRecord() {
      console.log({ api })

      // console.log('AAAAA', rules, this.userInfo)
      let res = null
      if (this.recordType === 'personal') {
        // this.getPersonalPlayRecord()
        res = api.getPersonalClickList({ ...this.queryParam })
      } else if (this.recordType === 'group') {
        if (this.userInfo.roles[0].roleCode == rules.group) {
          // this.getGroupPlayRecord()
          res = api.groupRepresent.getGroupRepresentRecord({
            ...this.queryParam,
          })
        } else {
          Toast('您没有权限查看集体点播记录')
        }
      }
      res
        .then((res) => {
          console.log('resssss', res)
          const { records, total } = res
          this.total = total
          records.map((record) => {
            this.playRecordList.push({
              cover: record.film.filmPicture,
              title: record.film.name,
              createTime: record.createTime,
              partCount: record.spellGroup,
              hopeDate:
                record.expectPlayStartTime + ' 至 ' + record.expectPlayEndTime,
              address: record.showPoint?.address || '暂无',
              ...record,
            })
          })
          this.showLoading = false
          if (this.playRecordList.length === this.total && this.total > 0) {
            this.showLoading = false
            this.finished = true
          }

        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    loadMore() {
      this.showLoading = true
      this.onReachBottom()
    },
    // 获取个人点播记录
    getPersonalPlayRecord() {
      api
        .getPersonalClickList(this.queryParam)
        .then((res) => {
          if (res.records.length < 1) {
            this.finished = true
          }
          console.log({ res })
          const { records, total } = res
          this.total = total
          records.map((record) => {
            this.playRecordList.push({
              cover: record.film.filmPicture,
              title: record.film.name,
              createTime: record.createTime,
              partCount: record.spellGroup,
              hopeDate:
                record.expectPlayStartTime + ' 至 ' + record.expectPlayEndTime,
              address: record.showPoint?.address || '暂无',
              ...record,
            })
          })
        })
        .catch((err) => {
          console.log({ err })
          Toast(err.message)
        })
    },
    // 获取集体点播记录
    getGroupPlayRecord() {
      api.groupRepresent
        .getGroupRepresentRecord(this.queryParam)
        .then((res) => {
          if (res.records.length < 1) {
            this.finished = true
          }
          res.records.map((record) => {
            this.playRecordList.push({
              films: record.films,
              createTime: record.createTime,
              partCount: record.spellGroup,
              hopeDate:
                record.expectPlayStartTime + ' 至 ' + record.expectPlayEndTime,
              address: record.showPoint?.address,
              ...record,
            })
          })
          this.showLoading = false
        })
        .catch((err) => {
          Toast(err.message)
        })
    },
    // 加载更多
    onReachBottom() {
      this.queryParam.pageNo++
      this.loading = true
      this.getPlayRecord()
    },
  },
  watch: {
    activeIndex(newVal) {
      if (newVal === 0) {
        this.queryParam.startDate = this.moment()
          .startOf('week')
          .format('YYYY-MM-DD')
        this.queryParam.endDate = this.moment()
          .endOf('week')
          .format('YYYY-MM-DD')
      } else {
        this.queryParam.startDate = '1970-01-01'
        this.queryParam.endDate = '2099-12-31'
      }
      this.queryParam.pageNo = 0
      this.playRecordList = []
      this.finished = false
      this.showLoading = false
      this.onReachBottom()
    },
  },
}
</script>

<style scoped lang="less">
.tab-title {
  padding: 20px;
  color: red;
}
.page {
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 99vh;
}
.play-record {
  overflow-y: scroll;
  height: 100%;
  max-height: 93vh;
  &-tab {
    width: 100vw;
    //margin: 16px 16px 0 16px;
  }
  &-list {
    box-sizing: border-box;
    //padding: 0.5rem 0;
  }
}
.oneLine {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 1.5rem;
}
</style>
