<!--
 * @Date: 2024-08-15 16:45:56
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2025-01-20 17:30:24
 * @FilePath: \zyt-mobile-frontend\src\views\me\playRecord\module\recordList.vue
-->
<template>
  <div class="play-record">
    <van-list
      v-if="playRecordList.length > 0"
      :loading="showLoading"
      :finished="finished"
      finished-text="没有更多了"
      @load="load"
      class="van-clearfix"
      :immediate-check="false"
    >
      <van-row
        class="play-record-list"
        v-for="(item, index) in playRecordList"
        :key="index"
      >
        <div class="play-record-status" v-if="recordType === 'personal'">
          <div class="play-record-status-text" v-if="item.status === 3">
            <van-icon name="warning" />
            未达到拼团人数
          </div>
          <!-- 状态 0-未开始 1-进行 2-已结束 3-失败 -->
          <div v-if="item.status === 3">
            <van-button type="danger" plain size="mini">拼团失败</van-button>
          </div>
          <div v-else-if="item.status === 2">
            <van-button type="success" plain size="mini">拼团成功</van-button>
          </div>
          <div v-else-if="item.status === 1">
            <van-button type="primary" plain size="mini">进行中</van-button>
          </div>
          <div v-else>
            <van-button type="default" plain size="mini">未开始</van-button>
          </div>
        </div>
        <template v-if="recordType === 'group'">
          <div class="play-record-item" v-for="(film, index) in item.films" :key="index">
            <div class="play-record-item-img">
              <img :src="film.filmPicture" alt="" />
            </div>
            <div class="play-record-item-info">
              <div class="play-record-item-info-title">{{ film.name }}</div>
              <div>年份 ：{{ film.year }}</div>
            </div>
          </div>
        </template>
        <template v-if="recordType === 'personal'">
          <div class="play-record-item">
            <div class="play-record-item-img">
              <img :src="item.cover" alt="" />
            </div>
            <div class="play-record-item-info">
              <div class="play-record-item-info-title">{{ item.title }}</div>
              <div>期望放映时间：</div>
              <div>
                {{ item.hopeDate }}
              </div>
              <div>已拼人数：{{ item.partCount }}人</div>
            </div>
          </div>
        </template>
        <div class="play-record-address">
          <div
            style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <van-icon name="clock" color="#20a3ff" />

            <span v-if="recordType==='group'">
              期望放映时间：{{ item.alternativePlayTime | formatDate }} {{ item.alternativePlayPeriod | formatTime }}
            </span>
            <span v-else>
              发起时间：{{ item.createTime }}
            </span>

            <van-button
              type="info"
              color="#5ea6e5"
              size="small"
              plain
              @click="share(item)"
              round
              style="height: 25px; margin-left: 10px"
            ><img src="@/assets/icon/share-blue.svg" alt="" srcset="" />
              分 享
            </van-button>
          </div>
          <span class="oneLine">
            <img src="@/assets/icon/localtion-blue.svg" alt="" srcset="" />
            {{ item.address }}({{ item.showPoint?.name||'暂无' }})
          </span>
        </div>
      </van-row>
    </van-list>
    <div v-else>
      <div class="play-record-list">
        <div class="play-record-item-empty">
          <div class="play-record-item-empty-img">
            <img src="@/assets/illustrations/Empty.svg" alt="" />
          </div>
          <div class="play-record-item-info">
            <div class="play-record-item-info-title">暂无点播记录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import moment from 'moment'
import common from '@/utils/common'

export default {
  props: {
    playRecordList: {
      type: Array,
      default: () => []
    },
    recordType: {
      type: String,
      default: 'personal'
    },
    showLoading: {
      type: Boolean,
      default: false
    },
    finished: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      error: false
    }
  },
  methods: {
    async load() {
      console.log('load');

      if (this.loading || this.finished) return
      try {
        this.loading = true
        this.error = false
        this.$emit('loadMore')
      } catch (e) {
        console.error('加载失败', e)
        this.error = true
      } finally {
        this.loading = false
      }
    },
    onReachBottom() {
      this.load()
    },
    // 分享
    share(plane) {
      const film = plane.film || plane.films[0]
      console.log('分享', film, plane)
      common.shareFilm(film, `${config.homeUrl}#/ruralFilm/nearbyCinema/filmDetail?filmId=${film.id}&showPointId=${plane.showPoint.id}`)
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format('YYYY年MM月DD日')
    },
    // 格式化时间
    formatTime(value) {
      return config.Times.find(item => item.value === value).name || ''
    },
  },
}
</script>

<style lang="less" scoped>
.play-record {
  overflow-y: scroll;
  max-height: 60vh;
  //border: 1px solid #e63633;
  height: 92vh !important;
  width: 99vw;
  margin: auto;

  &-list {
    box-sizing: border-box;
    //margin-bottom: 10px;
  }

  &-status {
    color: #e63633;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC, serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 0px 16px;
  }

  &-item {
    margin: 10px 16px;
    //border: 1px solid red;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-img {
      width: 100px;
      height: 132px;
      margin-right: 12px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: @border-radius-base;
      }
    }

    &-info {
      flex: 1;
      min-width: 0;
      padding: 0 0.5rem;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #363a44;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 132px;
      gap: 8px;

      &-title {
        height: 24px;
        font-size: 17px;
        font-family: PingFangSC-Semibold, PingFang SC, serif;
        font-weight: 600;
        color: #363a44;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  &-item-empty {
    //border:1px solid red;
    text-align: center;

    &-img {
      display: flex;
      height: 200px;

      > img {
        width: 50%;
        //居中
        margin: auto;
      }
    }
  }

  &-address {
    margin: 20px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC, serif;
    font-weight: 400;
    color: #363a44;
    line-height: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e9ec;
    word-break: break-all;
    white-space: normal;
  }
}
</style>
