<template>
  <div style="overflow-x: hidden">
    <VanSkeleton
        row="3"
        avatar
        avatar-size="90"
        row-width="230"
        avatar-shape="square"
        title
        :loading="loading"
    >
    </VanSkeleton>
    <VanSkeleton row="3" title :loading="loading"></VanSkeleton>
    <VanSkeleton row="3" title :loading="loading"></VanSkeleton>
    <VanSkeleton row="3" title :loading="loading"></VanSkeleton>

    <div v-if="!loading" class="container">
      <div class="Card">
        <div class="Card-image">
          <img :src="filmImg" style="width: 100%; height: 100%" alt=""/>
        </div>
        <div class="Card-content">
          <h3 class="Card-title">{{ filmTitle }} ({{ filmYear }})</h3>
          <div class="Card-score">
            <van-rate
                v-model="filmScore"
                :size="14"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                allow-half
                readonly
            />
            <span>{{ filmPlayNum }} </span>人点播
          </div>
          <div class="Card-length">
            <span>{{ filmType }}</span>
            <span>{{ filmLength }}分钟</span>
          </div>
        </div>
      </div>
      <!--      近期放映-->
      <div class="recentRecord" v-if="false">
        <div class="recentRecord-title box-title">
          <span>近期放映</span>
          <a href="">更多</a>
        </div>
        <div class="recentRecord-list">
          <!--          最多显示两条-->
          <p v-for="item in 2" :key="item">
            202年5月1日 浙江省杭州市西湖区哪个村放映
          </p>
        </div>
      </div>
      <div class="box-title" v-if="false">
        <span>附近点播</span>
      </div>
      <!--      最多显示一条-->
      <div class="addressCard" v-for="item in 0" :key="item">
        <div class="addressCard-text">
          <span
          ><van-icon
              name="location"
              size="20"
              color="#6b5aff"
          />浙江省杭州市西湖区哪个村放映</span
          >
          <span>11.2km</span>
        </div>
        <div class="addressCard-title">期望放映时间</div>
        <div class="addressCard-date">2019-05-01至2022-05-19</div>
        <button type="button" class="addressCard-btn" @click="together(item)">
          一起点
        </button>
      </div>
      <div class="box-title">
        <span>剧情简介</span>
      </div>
      <div class="filmDescription">
        <p>{{ filmDescription }}</p>
      </div>
      <div class="box-title">
        <span>演 员 表</span>
      </div>
      <div class="actorList">
        <p>
          导演：<span>{{ filmDirector }}</span>
        </p>
        <p>
          编剧：<span>{{ filmWriter }}</span>
        </p>
        <p>
          主演：<span>{{ filmActor }}</span>
        </p>
      </div>
      <div class="box-title">
        <span>影片剧照</span>
      </div>
      <div class="filmPhoto">
        <ul class="filmPhoto-list" v-if="filmPhotos.length > 0">
          <li
              class="filmPhoto-item"
              v-for="(item, index) in filmPhotos"
              :key="index"
          >
            <van-image lazy-load :src="item" radius="5" fit="cover"/>
          </li>
        </ul>
        <div class="filmPhoto-empty" v-else>
          <van-empty description="暂无剧照" image-size="50"/>
        </div>
      </div>
      <div class="box-button">
        <van-button
            round
            type="info"
            size="large"
            color="#3d8bff"
            style="height: 40px; width: 30%"
            @click="toPersonalOnDemand"
        >我要点播
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {Dialog, Toast} from 'vant'
import {api} from '@api'
import {getUserPoint} from '@/utils/util'
import { SetPvAndUv } from '@/mixins/PvAndUv'

export default {
  name: 'filmDetailView',
  mixins: [SetPvAndUv],
  data() {
    return {
      filmImg:
          'https://image11.m1905.cn/mdb/uploadfile/2022/0221/thumb_1_128_176_20220221101548787402.jpg',
      filmTitle: '牛牛德对决',
      filmScore: 4,
      filmPlayNum: 999999,
      filmType: '科幻',
      filmLength: 888,
      filmDescription: '',
      filmId: 888,
      filmYear: '2099',
      // 导演
      filmDirector: '',
      // 编剧
      filmWriter: '',
      // 主演
      filmActor: '',
      onDemand: false,
      filmPhotos: [],
      loading: true,
      // 展示简介全部
      showAll: false,
      userPoint: null
    }
  },
  mounted() {
    this.filmId = this.getUrlParam('id')
    this.userPoint = this.getUrlParam('showPointId')||getUserPoint()
    this.getFilmById(this.filmId)

  },
  // beforeRouteLeave(to, from, next) {
  //   if (to.path === '/onDemand') {
  //     //
  //     to.meta.keepAlive = true
  //     next()
  //   } else {
  //     next()
  //   }
  // },
  methods: {
    // 跳转到个人点播
    toPersonalOnDemand() {
      this.$router.replace({
        path: '/submitOnDemand',
        query: {
          filmId: this.filmId,
          filmTitle: this.filmTitle,
          playType: this.getUrlParam('playType') || 'personal',
        },
      })
    },
    // 根据id返回影片详细信息
    getFilmById(id) {
      api
          .getFilmAroundDetail({
            id,
            showPointId: this.userPoint.id
          })
          .then((data) => {
            const {code, result} = data
            if (!code===200){
              Toast(data.msg)
              return
            }
            this.filmTitle = result.name
            this.filmImg = result.filmPicture
            this.filmScore = result.score
            this.filmPlayNum = result.clickCount
            this.filmType = result.type
            this.filmLength = result.filmLength
            this.filmDescription = result.synopsis
            this.filmDirector = result.director
            this.filmWriter = result.scriptwriter
            this.filmActor = result.starrings
            this.filmYear = result.year
            this.loading = false
            this.toFilmDetail(result.personalTasks, result.groupTasks)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    // 跳转到影片拼团和放映任务的影片详情页
    toFilmDetail(personalTasks = [], groupTasks = []) {
      console.log(personalTasks, groupTasks);
      const personalCount = personalTasks.length
      const groupCount = groupTasks.length
      if (personalCount > 0 || groupCount > 0) {
        Dialog.confirm({
          title: '温馨提醒',
          // 【影片名】在放映点:【放映点名称A】等附近放映点已有多个放映计划，是否前往查看观看?
          message: `【${this.filmTitle}】在放映点:【${this.userPoint.name}】等附近放映点已有多个放映计划，是否前往查看观看?`,
          confirmButtonText: '前往',
          cancelButtonText: '暂不',
        }).then(() => {
          this.$router.push({
            // /ruralFilm/nearbyCinema/filmDetail/10
            path: '/ruralFilm/nearbyCinema/filmDetail?filmId=' + this.filmId,
            query: {
              playType: this.getUrlParam('playType'),
            },
          })
        })
      }

    },
    // 获取URL参数
    getUrlParam(name) {
      return this.$route.params[name] || ''
    },
    // 一起点播
    together(item) {
      Toast('点播成功')
      console.log(item)
    },
  },
}
</script>

<style scoped lang="less">
.bg {
  overflow-x: hidden;
  width: 100%;
  height: 118px;
  background-image: radial-gradient(circle at 40% 0%,
  rgba(228, 221, 255) 0%,
  rgba(255, 255, 255, 0) 30%),
  radial-gradient(circle at 60% 0%,
  rgba(213, 219, 255, 0.6) 0%,
  rgba(255, 255, 255, 0) 20%),
  radial-gradient(circle at 90% 0%,
  rgba(240, 252, 255) 0%,
  rgba(255, 255, 255, 0) 20%);
}

.container {
  padding: 0 10px;
  overflow-x: hidden;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
}

.Card {
  padding-top: 5px;
  display: flex;
  flex-direction: row;

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  background-image: radial-gradient(circle at 40% 0%,
  rgba(228, 221, 255) 0%,
  rgba(255, 255, 255, 0) 30%),
  radial-gradient(circle at 60% 0%,
  rgba(213, 219, 255, 0.6) 0%,
  rgba(255, 255, 255, 0) 20%),
  radial-gradient(circle at 90% 0%,
  rgba(240, 252, 255) 0%,
  rgba(255, 255, 255, 0) 20%);
  overflow-x: hidden;

  &-image {
    width: 100px;
    padding: 8px 8px 8px 0;

    > img {
      width: 100%;
      vertical-align: middle;
      margin: 0 auto;
      border-radius: 5px;
    }
  }

  &-title {
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
    font-weight: 600;
    color: #363a44;
    line-height: 24px;
    margin-top: 5px;
  }

  &-score {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC, sans-serif;
    font-weight: 500;
    color: #b9bcbf;
    line-height: 18px;
    margin-top: -10px;

    > span {
      width: 30px;
      height: 18px;
      font-size: 14px;
      //font-family: PingFangSC-Medium, PingFang SC,sans-serif;
      font-weight: 500;
      color: @button-color;
      line-height: 18px;
    }
  }

  &-length {
    margin-top: 30px;
    height: 19px;
    width: 120px;
    border-radius: 2px;
    border: 1px solid @color-primary;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @color-primary;
    line-height: 19px;

    > span {
      //display: inline-block;
      text-align: center;

      &:first-child {
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff;
        padding: 0 5px;
        background: @color-primary;
      }

      &:nth-child(2) {
        width: 60px;
        //border:1px solid #000;
        padding: 0 5px;
        text-align: center;
      }
    }
  }

  &-btn {
    width: 32px;
    height: 32px;
    background: rgba(164, 105, 105, 0.001);
    box-shadow: 0 4px 10px 0 rgba(136, 168, 200, 0.2);
    border: 2px solid #fff;
    backdrop-filter: blur(3px);
    position: absolute;
    right: 10px;
    top: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50px;

    &-text {
      position: absolute;
      width: 50px;
      text-align: center;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #363a44;
      line-height: 17px;
      transform: translateY(180%);
    }
  }
}

.recentRecord {
  &-list {
    //width: 272px;
    //height: 19px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC, serif;
    color: #333333;
    line-height: 19px;
  }
}

.box-title {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC, serif;
  color: #333333;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > a {
    width: 40px;
    height: 20px;
    background: rgba(216, 216, 216, 0.27);
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    font-family: MicrosoftYaHei, serif;
    color: #b3b5b9;
    line-height: 20px;
  }

  > span {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #333333;
    line-height: 20px;
    font-weight: bold;

    &:after {
      display: inline-block;
      content: '';
      transform: translate(-45px, 10px);
      border-radius: 5px;
      width: 30px;
      height: 3px;
      background: @color-primary;
    }
  }
}

.addressCard {
  height: 91px;
  background: #f3f6f9;
  border-radius: @border-radius-base;
  border: 1px solid #e6ebf0;
  margin: 10px 0;
  padding: 5px;

  &-text {
    display: flex;
    font-family: MicrosoftYaHei;
    justify-content: space-between;
    font-size: 14px;
    color: #333333;
    height: 40px;
    line-height: 30px;
  }

  &-title {
    width: 84px;
    height: 19px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC, sans-serif;
    padding-left: 16px;
    color: #888888;
    line-height: 19px;
  }

  &-date {
    width: 88%;
    height: 30px;
    font-size: 14px;
    padding-left: 16px;
    font-family: PingFangSC-Medium, PingFang SC, sans-serif;
    color: #333333;
    line-height: 30px;
  }

  &-btn {
    width: 60px;
    height: 24px;
    background: #e3efff;
    border-radius: 12px;
    border: 1px solid #428efa;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #428efa;
    float: right;
    transform: translateY(-30px);
  }
}

.filmDescription {
  //height: 95px;
  font-size: 14px;
  color: #666666;
  line-height: 19px;
  text-indent: 30px;
  margin: 20px 10px;

  > p {
    margin: 0;
    padding: 0;
  }
}

.actorList {
  width: auto;
  color: #686b73;
  background: #f3f6f9;
  border-radius: @border-radius-base;
  opacity: 0.67;
  border: 1px solid #e6ebf0;
  margin: 10px auto;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;

  > p {
    width: 50%;
    margin: 0;

    > span {
      color: #000 !important;
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;
      font-family: PingFangSC-Medium, PingFang SC;
    }
  }
}

.filmPhoto {
  margin-top: 10px;
  margin-bottom: 80px;
  height: 120px;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;

  &-empty {
    //border: 1px solid #E6EBF0;
    min-height: 150px;
  }

  &-list {
    width: 90%;
  }

  &-item {
    width: 127px;
    height: 90px;
    margin: 5px;
    display: inline-block;

    > img {
      width: 100%;
      height: 100%;
    }
  }
}

.box-button {
  position: fixed;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
