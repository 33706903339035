<template>
  <div class="page">
    <!--    放映任务-->
    <div class="planList">
      <div class="plan-filter">
        <van-dropdown-menu>
          <van-dropdown-item @change="onSelectPlanStatus" v-model="query.planStatus" :options="planStatus" />
          <van-dropdown-item @change="onSelectPlanDate" v-model="query.timeRange" :options="planDates" />
          <van-dropdown-item @change="onSelectPoint" v-model="query.selectPoint" :options="pointList" />
        </van-dropdown-menu>
      </div>
    </div>
    <div class="list">
      <div class="list-item" v-for="(day, index) in filterScreeners(playRecordList)" :key="index">
        <div class="list-item-header">
          <div class="list-item-header-left">
            <div
              class="list-item-header-left-status"
              :style="{ color: statusColor(day.status) }"
            >
              <span
                class="status-mark"
                :style="{ backgroundColor: statusColor(day.status) }"
              ></span>
              <span v-if="day.status === 0"> 待审核</span>
              <span v-if="day.status === 2"> 已通过</span>
              <span v-if="day.status === 3"> 已取消</span>
            </div>
          </div>
          <div class="list-item-header-right">
            <!--            <span-->
            <!--              style="color: #f5a623"-->
            <!--              @click="cancelDemand(day)"-->
            <!--              v-if="day.status === 1 || day.status === 0"-->
            <!--            >-->
            <!--              撤销-->
            <!--              <van-icon name="revoke" />-->
            <!--            </span>-->
            <span style="color: #007aff" @click="viewDemand(day)">
              查看
              <van-icon name="eye" color="#007aff" />
            </span>
          </div>
        </div>
        <div class="list-item-body">
          <p>
            {{ day.Date }} {{ new Date(day.Date) | formatWeek }}
            {{ day.playStartTime }}
          </p>
          <!-- 放映影片 -->

          <div>
            放映影片：{{ day.films.map((film) => film.name).join('/') }}
          </div>
          <!--          <div>放映地点：{{ day.showPoint.name }}({{ day.playPlace }})</div>-->
          <div>放映地点：{{ day.playPlace }}({{ day.showPoint.name }})</div>
          <div>放映员：{{ day.submitter }}</div>
        </div>
      </div>
      <div v-if="playRecordList.length === 0">
        <van-empty
          description="暂无数据"
          :image-size="100"
          :image-color="['#f5a623', '#E63633', '#52c41a']"
        ></van-empty>
      </div>
    </div>
    <!--    点播记录明细-->
    <van-popup v-model="showDetail" v-if="showDetail" :style="{ width: '80%','max-height': '80%','border-radius': '5px' }" closeable @close="closeDetail">
      <div class="list-item">
        <div class="list-item-header">
          <div class="list-item-header-left">
            <div
              class="list-item-header-left-status"
              :style="{ color: statusColor(playRecordDetail.status) }"
            >
              <span
                class="status-mark"
                :style="{ backgroundColor: statusColor(playRecordDetail.status) }"
              ></span>
              <span v-if="playRecordDetail.status === 0"> 待审核</span>
              <span v-if="playRecordDetail.status === 2"> 已通过</span>
              <span v-if="playRecordDetail.status === 3"> 已取消</span>
            </div>
          </div>
        </div>
        <div class="list-item-body">
          <p>
            {{ playRecordDetail.Date }} {{ new Date(playRecordDetail.Date) | formatWeek }}
            {{ playRecordDetail.playStartTime }}
          </p>
          <!-- 放映影片 -->
          <h4 style="margin: 10px 0 0 0"> 放映影片：</h4>
          <van-list>
            <van-cell
              v-for="film in playRecordDetail.films"
              :key="film.id"
              :title="film.name"
            >
              <template #icon>
                <van-image
                  width="80"
                  height="120"
                  :src="film.filmPicture"
                  fit="cover"
                  style="transform: translateX(-10px)"
                />
              </template>
              <template #label>
                <div>
                {{`导演: ${film.director} | 类型: ${film.type} | 时长: ${film.filmLength}分钟`}}
                </div>
                <van-tag type="primary" v-for="label in film.labels.split(',')" style="margin-right: 8px" :key="label">{{ label }}</van-tag>
              </template>
            </van-cell>
          </van-list>

          <div style="font-size: 14px; margin-top: 5px;">放映地点：{{ playRecordDetail.playPlace }}</div>
<!--          放映点名称-->
          <div style="font-size: 14px; margin-top: 5px;">放映点名称：{{ playRecordDetail.showPoint.name }}</div>
          <div style="font-size: 14px; margin-top: 5px;">放映员：{{ playRecordDetail.submitter }}</div>
        </div>

      </div>
    </van-popup>
  </div>
</template>

<script>
import api from '@/api/api'
import { Toast } from 'vant'
import playRecord from '../playRecord/index.vue'

export default {
  name: 'PlayRecordHistory',
  computed: {
    playRecord() {
      return playRecord
    },
  },
  data() {
    return {
      // 初始化数据
      query: {
        // 审核状态
        planStatus: -1, // -1:全部 0:待审核 2:已通过
        // 时间范围
        timeRange: 0, // 0:默认时间 1:近一周 2:近一个月 3:近三个月
        // 选择的点播地点
        selectPoint: 0, // 0:全部 1:线上 2:线下
      },
      planStatus: [
        // 状态 0-待审核 2-已通过
        { text: '全部状态', value: -1 },
        { text: '待审核', value: 0 },
        { text: '已通过', value: 2 },
      ],
      planDates: [
        { text: '默认时间', value: 0 },
        // 今天
        { text: '今天', value: 1 },
        { text: '本周', value: 7 },
        { text: '本月', value: 30 },
        { text: '三个月', value: 90 },
      ],
      pointList: [
        { text: '全部', value: 0 },
      ],
      // 点播记录数据
      playRecordList: [],
      playRecordDetail: null,
      showDetail: false,
    }
  },
  mounted() {
    // 获取点播记录数据
    this.getScreeningRecordList()
  },
  methods: {
    getScreeningRecordList() {
      const params = {
        endDate: null,
        startDate: null,
        status: this.query.planStatus === -1 ? null : this.query.planStatus,
        // showPointId: 10006829918903,
      }
      switch (this.query.timeRange) {

        case 0:
          // 最近一周
          params.startDate = this.moment().subtract(1, 'week').format('YYYY-MM-DD')
          params.endDate = this.moment().format('YYYY-MM-DD')
          break
        case 1:
          // 今天
          params.startDate = this.moment().format('YYYY-MM-DD')
          params.endDate = this.moment().format('YYYY-MM-DD')
          break
        case 7:
          // 本周一到本周日
          params.startDate = this.moment().startOf('week').format('YYYY-MM-DD')
          params.endDate = this.moment().endOf('week').format('YYYY-MM-DD')
          break
        case 30:
          // 本月
          params.startDate = this.moment().startOf('month').format('YYYY-MM-DD')
          params.endDate = this.moment().endOf('month').format('YYYY-MM-DD')
          break
        case 90:
          // 三个月前到今天
          params.startDate = this.moment().subtract(3, 'month').format('YYYY-MM-DD')
          params.endDate = this.moment().format('YYYY-MM-DD')
          break
      }
      switch (this.query.planStatus) {
        case -1:
          params.status = -1
          break
        case 0:
          params.status = 0
          break
        case 2:
          params.status = 2
          break
      }
      api.filmmaker.getScreeningRecordList(params).then(res => {
        const { code, result, message } = res
        if (code === 200) {
          this.playRecordList = result.map((play) => {
            return {
              ...play,
              id: play.id,
              Date: play.playStartDate,
              playStartTime: play.playStartTime,
              playEndTime: '12:00',
              status: play.status,
              playPlace: play.playPlace,
              films: play.films,
            }
          })
          const pointList = []
          result.forEach(item => {
            if (pointList.findIndex(p => p.value === item.showPoint.id) === -1) {
              pointList.push({ text: item.showPoint.name, value: item.showPoint.id })
            }
          })
          this.pointList = [{
            text: '全部',
            value: 0,
          }, ...pointList]
          console.log(this.pointList)
        } else {
          Toast.fail(message)
        }
      })
    },
    onSelectPlanStatus(value) {
      this.query.planStatus = value
      this.getScreeningRecordList()
    },
    onSelectPlanDate(value) {
      this.query.timeRange = value
      this.getScreeningRecordList()
    },
    onSelectPoint(value) {
      this.query.selectPoint = value
      // this.getScreeningRecordList()
    },
    filterScreeners(list) {
      if (this.query.selectPoint === 0) {
        return list
      }
      return list.filter(item => {
        return item.showPoint.id === this.query.selectPoint
      })
    },
    statusColor(status) {
      switch (status) {
        case 0:
          return '#f5a623'
        case 2:
          return '#52c41a'
        case 3:
          return '#E63633'
        default:
          return '#f5a623'
      }
    },
    viewDemand(detail) {
      this.playRecordDetail = detail
      this.showDetail = true
    },
    closeDetail() {
      this.showDetail = false
    },
  },
  filters: {
    // 日期格式化为星期
    formatWeek(time) {
      const date = new Date(time)
      const week = ['日', '一', '二', '三', '四', '五', '六']
      const weekDay = date.getDay()
      return `星期${week[weekDay]}`
    },

  },
}
</script>

<style scoped lang="less">
/* 添加样式 */
.page {
  min-height: 100vh;
}

.list {
  margin: 12px;

  &-item {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 6px;
    margin-bottom: 8px;
    background-color: #fff;
    border: 1px solid #e8e9ec;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #e8e9ec;

      .status-mark {
        display: inline-block;
        width: 8px;
        height: 14px;
        border-radius: 2px;
      }

      &-left {
      }

      &-right > a {
        color: @color-primary;
      }
    }

    &-body {
      padding: 10px;

      & > p {
        font-weight: bold;
        font-size: 16px;
        color: #363a44;
        line-height: 22px;
        height: 22px;
        text-align: left;
        padding: 0;
        margin: 0;

        font-style: normal;
      }

      & > div {
        color: #686b73;
        padding: 2px;
      }
    }
  }
}
</style>
