<!--
* @Date: 2024-08-09 11:05:55
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-11-14 21:44:15
 * @FilePath: \zyt-mobile-frontend\src\views\ruralFilm\pages\selectPoint\index.vue
-->
<template>
  <div class="community-selector">
    <div class="community-selector-address">
      <div>
        <span>您当前的位置</span>
      </div>
      <div>
        <span class="community-selector-address-btn" @click="showArea = true">
          更改地址
          <van-icon name="aim" size="16"/>
        </span>
      </div>
    </div>
    <div class="community-selector-title">
      <van-icon name="location" size="20" color="#1492FF"/>
      {{ gpsAddress || '未定位' }}
      <van-button plain v-if="!gpsAddress" @click="autoGps" round size="small">
        重新获取定位
      </van-button>
    </div>
    <div class="community-selector-content">
      <div
          class="community-selector-list"
          v-for="(item, index) in showList"
          :key="item.id"
          @click="handleSelectPoint(item)"
      >
        <van-cell-group>
          <van-cell
              center
              :class="{'community-selector-list-active': item.id === selectedPointId}"
              :title="`${index + 1}.${item.name} ${item.meter ? `(${distanceToKm(item.meter)})` : ''}`"
              size="large"
              :label="item.address"
          >
            <template #extra>
              <van-icon
                  name="certificate"
                  v-if="item.id === selectedPointId"
                  color="#1989fa"
                  size="30"
              />
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <div class="community-selector-btn" v-if="showList.length > 0">
      <van-button type="info" round block @click="confirmSelect">确定选择</van-button>
    </div>
    <div v-else>
      <van-empty
          class="custom-image"
          image="https://img.dev.zhijiangfilm.com/i/2024/10/08/6704a96f6c8ee.png"
          description="暂未找到符合条件的放映点，请重新选择地址"
      />
    </div>
    <van-popup v-model="showArea" position="bottom">
      <div>
        <van-cascader
            v-model="communityCode"
            title="请选择所在社区"
            :close-on-click-overlay="false"
            round
            active-color="#1989fa"
            :options="options"
            @change="addressChange"
            @close="closeArea"
            @finish="onFinish"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import {Dialog, Toast} from 'vant';
import {api} from '@api';
import {SetPvAndUv} from '@/mixins/PvAndUv';

export default {
  name: 'communitySelector',
  mixins: [SetPvAndUv],
  props: {
    communityName: {
      type: String,
      default: '请选择放映点',
    },
  },
  data() {
    return {
      showArea: false,
      options: [],
      gpsAddress: '',
      isCityLevel: true,
      communityCode: null,
      communityInfo: null,
      showList: [],
      selectedPointId: null,
      queryPoint: {
        areaId: null,
        lng: 0,
        lat: 0,
      },
    };
  },
  mounted() {
    this.autoGps();
    this.loadArea(3);
  },
  methods: {
    // 加载区域
    loadArea(levelIndex) {
      console.log('加载区域');
      if (levelIndex === 3 && this.options.length === 0) {
        this.getCityList();
      }
    },
    back() {
      this.$router.back();
    },
    async getCityList() {
      if (this.options.length > 0) return;
      try {
        const res = await api.getCityList();
        this.options = res.map((ct) => ({
          text: ct.name,
          value: ct.id,
          children: [],
        }));
        this.isCityLevel = res[0].level === 3;
      } catch (error) {
        Toast('获取城市列表失败，请重试。');
      }
    },
    async getChildArea(level, id) {
      try {
        const res = await api.getChildList({id});
        return res;
      } catch (err) {
        console.error(err);
        Toast('获取子区域失败，请重试。');
        return [];
      }
    },
    async addressChange(value) {
      const startLevel = 2; // 代表市级
      const levelIndex = value.tabIndex + startLevel + 1;

      if (value.tabIndex === 6 || levelIndex === 6) return;

      const childAreas = await this.getChildArea(levelIndex, value.value);
      this[`levels${levelIndex}`] = childAreas.map(i => ({
        text: i.name,
        value: i.id,
        checkStrictly: levelIndex < 5,
        children: levelIndex < 5 ? [] : undefined,
      }));
      value.selectedOptions[value.tabIndex].children = this[`levels${levelIndex}`];
    },
    async onFinish({selectedOptions}) {
      const value = selectedOptions.map(r => ({text: r.text, value: r.value}));
      this.$store.commit('changeUserAddress', value);
      this.communityInfo = selectedOptions;
      this.gpsAddress = selectedOptions.map(r => r.text).join(' ');
      this.showArea = false;
      this.queryPoint = {areaId: this.communityCode, lng: 0, lat: 0};
      await this.getCommunityList();
    },
    async autoGps() {
      try {
        const result = await ZWJSBridge.getLocation();
        this.gpsAddress = result.detailAddress;
        this.queryPoint = {
          lng: result.longitude,
          lat: result.latitude,
          areaId: null,
        };
        await this.getCommunityList();
      } catch (error) {
        console.error(error);
        Dialog.confirm({
          title: '此服务需要获取您的位置信息',
          message: '为您推荐最近的放映点及影片信息',
          confirmButtonColor: '#1492FF',
          confirmButtonText: '打开定位',
          cancelButtonText: '手动选择地址',
          showCancelButton: true,
        })
            .then(() => {
              Toast('请检查系统设置是否允许定位');
            })
            .catch(() => {
              this.showArea = true;
            });
      }
    },
    async getCommunityList() {
      const res = await api.getShowPoints(this.queryPoint);
      if (res.code === 200) {
        this.showList = res.result.surrounds || [];
      } else {
        this.showList = [];
        Toast(res.message);
      }
    },
    closeArea() {
      if (this.gpsAddress) {
        this.showArea = false;
      }
    },
    handleSelectPoint(item) {
      this.selectedPointId = this.selectedPointId === item.id ? null : item.id;
    },
    confirmSelect() {
      if (this.selectedPointId) {
        const {form, back} = this.$route.query;

        if (form) {
          this.saveUserPoint(this.selectedPointId);
          const isBack = back === 'true';
          isBack ? this.$router.go(-1) : this.$router.push({path: form, query: {communityId: this.selectedPointId}});
        } else {
          this.$router.push({
            name: 'ruralFilm',
            query: {communityId: this.selectedPointId, communityName: this.selectedPointId}
          });
        }
      } else {
        Toast('请选择放映点');
      }
    },
    saveUserPoint(pointId) {
      const point=this.showList.find(p=>p.id===pointId)
      console.log('保存放映点', point)
      if (point) {
        this.$store.dispatch('changeuserPoint', point);
        // try {
        //   // 尝试存储到本地
        //   ZWJSBridge.setLocalStorage({key: 'userPoint', value: JSON.stringify(point)})
        //       .then(() => console.log('保存成功'))
        //       .catch(() => Toast('保存放映点失败。'));
        // } catch (error) {
        //   console.error(error);
        //   // Toast('保存放映点失败。');
        // }
        localStorage.setItem('userPoint', JSON.stringify(point))
        Toast('保存放映点成功。')
      } else {
        Toast('请选择放映点');
      }
    },
    distanceToKm(distance) {
      return (distance / 1000).toFixed(2) + '公里';
    },
  },
  watch: {
    show(val) {
      if (val) this.autoGps();
    },
    showArea(val) {
      if (!val && !this.communityInfo) {
        setTimeout(() => this.showArea = true, 300);
      }
    },
  },
}
</script>

<style scoped lang="less">
.custom-image .van-empty__image {
  width: 90px;
  height: 90px;
}

.community-selector {
  padding: 4px;
  font-size: @font-size-base;
  color: @text-color-primary;
  background: @bg-color-base;

  &-title {
    font-size: @font-size-medium;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 12px;
  }

  &-list {
    margin: 12px 12px 0 12px;

    &-active {
      border: 1px solid @color-primary;
      border-radius: 4px;
      transition: all 0.5s ease;
      transform: scale(1.01);
    }
  }

  &-content {
    overflow-y: scroll;
    max-height: 80vh;
  }

  &-address {
    font-size: 14px;
    display: flex;
    padding: 0 12px;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;
    font-weight: bold;

    &-btn {
      color: @color-primary;
    }
  }

  &-btn {
    position: fixed;
    bottom: 5vh;
    left: 30vw;
    width: 40vw;
  }
}

::v-deep .van-cascader__options {
  height: calc(65vh);
  transform: translateY(-10px);
}
</style>
