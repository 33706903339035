<template>
  <div class="page">
    <!--    放映任务-->
    <div class="planList">
      <div class="plan-filter">
        <van-dropdown-menu
        >
          <van-dropdown-item @change="onSelectPlanStatus" v-model="query.planStatus" :options="planStatus" />
          <van-dropdown-item @change="onSelectPlanDate" v-model="timeRange" :options="planDates" />
          <van-dropdown-item @change="onSelectPoint" v-model="selectPoint" :options="pointList" />
        </van-dropdown-menu>

      </div>

      <van-cell
        class="planItem box"
        v-for="(plan, index) in plans"
        :key="index"
      >
        <!-- planStatus:{{ plan.planStatus }} - filmteamConfirmStatus:{{ plan.filmteamConfirmStatus }} -->
        <div
          class="planItem-header status"
          :class="{
            'status-0': plan.planStatus === 0,
            'status-1': plan.planStatus === 1,
            'status-3': plan.planStatus === 3,
            'status-4': plan.planStatus === 4,
          }"
        >
          <div class="planItem-dateTime">
            {{ plan.playDatetime | formatDate }}
          </div>
          <div class="planItem-address">
            <span>
              <van-icon name="location" size="20px" color="#50baff"></van-icon>
            </span>
            <span style="max-width: 85vw">
              {{ plan.showPoint.name }}({{ plan.showPoint.address }})
            </span>
          </div>
        </div>
        <div class="planItem-films">
          <div class="planItem-films-header">
            <div>顺序</div>
            <div>影片名称</div>
          </div>
          <div
            class="planItem-films-body"
            v-for="(film, index) in plan.films"
            :key="index"
          >
            <div>
              <span :class="['mark' + (index + 1)]">{{ index + 1 }}</span>
            </div>
            <div>
              {{ film.name }}
            </div>
          </div>
        </div>
<!--        <div class="planItem-line" v-if="plan.planStatus === 4">-->
<!--          <span>已完成</span>-->
<!--        </div>-->
<!--        <div class="planItem-line" v-else-if="plan.planStatus === 3">-->
<!--          <span>已取消</span>-->
<!--        </div>-->
        <div class="planItem-btns"   >
          <!--          <div class="planItem-btns" >-->
          <div>
            <van-button
              type="warning"
              size="small"
              :disabled="[3,4].includes(plan.planStatus)"
              plain
              round
              block
              @click="cancelPlan(plan)"
              icon="close"
            >
              取消任务
            </van-button>
          </div>
          <div>
<!--            {{plan.projectionistEditable === false}}-->
<!--            {{[3,4].includes(plan.planStatus)}}-->
            <van-button
              type="info"
              size="small"
              :disabled="plan.projectionistEditable === false||[3,4].includes(plan.planStatus)"
              plain
              round
              block
              icon="edit"
              @click="modifyPlan(plan)"
            >
              修改任务
            </van-button>
          </div>
<!--          <div>-->
<!--            <van-button-->
<!--              type="info"-->
<!--              size="small"-->
<!--              round-->
<!--              block-->
<!--              @click="confirmPlan(plan)"-->
<!--            >-->
<!--              确认任务-->
<!--            </van-button>-->
<!--          </div>-->
        </div>
      </van-cell>
      <van-empty v-if="plans.length === 0">
        <div class="empty-block-text">暂无放映任务</div>
      </van-empty>
    </div>
    <van-popup v-model="showPoint" round position="bottom">
      <van-picker
        show-toolbar
        :columns="pointList"
        @cancel="showPicker = false"
        @confirm="onConfirmPoint"
      />
    </van-popup>
    <edit ref="edit" @success="onLoad"></edit>
    <cancel ref="cancel" @success="onLoad"></cancel>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { api } from '@/api/index'
import Edit from './modules/edit.vue'
import Cancel  from './modules/cancel.vue'

export default {
  name: 'screenPlansView',
  components: {
    Edit,
    Cancel,
  },
  data() {
    return {
      plans: [],
      tempPlanData: {},
      cancelMessage: '',
      pageIndex: 0,
      showLoad: false,
      finished: false, // 是否加载完毕
      showPoint: false, // 显示选择放映点场地弹窗
      modifyMessage: '',
      selectPoint: null,
      // 选择时间跨度
      timeRange: null,
      planStatus: [
        {text: '已确认', value: 1},
        {text: '已取消', value: 3},
        {text: '已完成', value: 4},
      ], // 1:待确认 2:已确认 3:已取消
      planDates:[
        {text: '默认时间', value: null},
        {text: '今天', value: 1},
        {text: '本周', value: 7},
        {text: '本月', value: 30},
        {text: '90天内', value: 90},
    ],
      query: {
        page: 1,
        pageSize: 1000,
        planStatus: 1,
        startDate:this.moment().format('YYYY-MM-DD'),
        endDate:this.moment().add(30, 'days').format('YYYY-MM-DD'),
      },
      pointList: [
        {
          text: '全部',
          value: null,
        },
      ],
    }
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    // 加载数据 isUpdatePoint 是否更新点场地列表
    onLoad(isUpdatePoint = true) {
      api.filmmaker.getPlanConfirmList({ ...this.query }).then((res) => {
        const { result, total, success } = res
        if (success) {
          this.plans = result.records.map((plans) => {
            if (
              !this.pointList.some(
                (item) => item.value === plans.showPoint.id,
              ) &&
              isUpdatePoint
            ) {
              this.pointList.push({
                text: plans.showPoint.name,
                value: plans.showPoint.id,
                data: plans.showPoint,
              })
            }
            return {
              ...plans,
            }
          })
          this.finished = true
          this.showLoad = false
        } else {
          this.showLoad = false
          Toast.fail(res.message)
        }
      })
    },
    // 选择任务状态
    onSelectPlanStatus(value) {
      this.query.planStatus = value
      this.onLoad()
    },
    // 选择时间跨度
    onSelectPlanDate(value) {
      console.log({ value })
      switch (value){
        case 1:
          this.query.startDate = this.moment().format('YYYY-MM-DD')
          this.query.endDate = this.moment().format('YYYY-MM-DD')
          break
        case 7:
          // 本周星期一
          this.query.startDate = this.moment().startOf('week').format('YYYY-MM-DD')
          this.query.endDate = this.moment().endOf('week').format('YYYY-MM-DD')
          break
        case 30:
          this.query.startDate = this.moment().startOf('month').format('YYYY-MM-DD')
          this.query.endDate = this.moment().endOf('month').format('YYYY-MM-DD')
          break
        case 90:
          this.query.startDate =  this.moment().subtract(90, 'days').format('YYYY-MM-DD')            // 前90天
          this.query.endDate = this.moment().format('YYYY-MM-DD')
          break
        default:
          this.query.endDate = this.moment().add(30, 'days').format('YYYY-MM-DD')
      }
      this.onLoad()
    },
    // 选择放映点场地
    onSelectPoint(value) {
      console.log({ value })
      this.selectPoint = value
      // this.showPoint = false
      this.query.showPointId = value
      this.onLoad(false)
    },
    /** 修改任务
     * @param {Object} plan
     */
    modifyPlan(plan) {
      this.$refs.edit.show(plan)
    },
    /** 取消任务
     * @param {Object} plan
     */
    cancelPlan(plan) {
      this.$refs.cancel.show(plan)
    },
    //用影片选择控件中选择影片添加到当前修改列表
    addTypeFilmToSelectArea(filmId, filmName) {
      // console.log(filmId, filmName);
      // 判断movieList中是否已经存在该影片
      if (this.tempPlanData.movieList.some((item) => item.filmId === filmId)) {
        Toast('该影片已存在')
        return
      }
      this.tempPlanData.movieList.push({
        filmId: filmId,
        filmName: filmName,
        sort: this.tempPlanData.movieList.length + 1,
      })
    },
    // // 确认任务
    // confirmPlan(plan) {
    //   console.log(plan);
    //   Dialog.confirm({
    //     title: '确认任务',
    //     message: '确认后需要放映公司管理员修改，确定确认该任务吗？',
    //   }).then(() => {
    //     // on confirm
    //     console.log('确认任务')
    //     api.filmmaker.confirmPlan({planId: plan.id}).then((res) => {
    //       const { success,message } = res
    //       console.log(res)
    //       if (success) {
    //         Toast('确认成功')
    //         this.onLoad()
    //       } else {
    //         Toast(message)
    //       }
    //     })
    //   })
    // },
    // 选择放映点场地
    onConfirmPoint(item) {
      console.log(item)
      // this.selectPoint = item
      // this.showPoint = false
      this.query.showPointId = item.value
      this.onLoad(false)
    },
  },
  filters: {
    // 2024-09-26 18:00:00   => 2024-09-26 星期一 18:00
    formatDate(value) {
      const val = value.replace(/-/g, '/')
      const date = new Date(val)
      const week = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ]
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
      const minute =
        date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
      return `${year}-${month}-${day} ${week[date.getDay()]} ${hour}:${minute}`
    },
  },
}
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
}

.box {
  width: 100%;
  //height: 283px;
  background: #ffffff;
  border-radius: @border-radius-base;
  padding: 0;
}

.planList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.plan-filter {
  width: 100%;
}

.planItem {
  border: 1px solid #e6ebf0;
  margin: 10px;
  &-header {
    width: 100%;
    max-height: 87px;
    background: url('./../../../assets/illustrations/card.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 0 0 4px 4px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    position: relative;
  }
  &-dateTime {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #363a44;
    padding-left: 25px;
    margin-top: 5px;
  }
  &-line{
    width: 100%;
    padding: 10px;
    background: #E6EBF0;
  }
  &-address {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363a44;
    margin: 5px 0;
    display: flex;
    //超出2行显示省略号换行
    & > span {
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &-films {
    width: 100%;
    margin: 0 auto;
    &-header {
      width: 100%;
      height: 34px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #244e8a;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      background: #f8f8f8;

      > div:first-child {
        width: 30%;
      }
      > div:last-child {
        width: 70%;
        text-align: center;
      }
    }

    &-body {
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #244e8a;
      line-height: 40px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 1px solid #f0f5fe;

      > div:first-child {
        width: 30%;
      }

      > div:last-child {
        width: 70%;
        text-align: center;
      }
    }
  }

  &-btns {
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      flex: 1;
      padding: 5px 10px;
    }
  }
}

.mark1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  width: 16px;
  height: 18px;
  background: linear-gradient(180deg, #8fece7 0%, #56d5cb 100%);
  box-shadow: 0 2px 3px 0 #caf2ef;
  border-radius: 3px;
  margin: 0 auto;
}

.mark2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  width: 16px;
  height: 18px;
  background: linear-gradient(180deg, #abcbff 0%, #729cff 100%);
  box-shadow: 0 2px 3px 0 rgba(143, 185, 255, 0.4);
  border-radius: 3px;
  margin: 0 auto;
}

.mark3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  width: 16px;
  height: 18px;
  background: linear-gradient(180deg, #d3b0ff 0%, #d26eff 100%);
  box-shadow: 0 2px 3px 0 #f6d3ff;
  border-radius: 3px;
  margin: 0 auto;
}

.status {
  &::after {
    content: '';
    display: block;
    width: 57px;
    height: 57px;
    position: absolute;
    left: 80%;
    //background: url("./../../../assets/illustrations/1.png") no-repeat center center;
  }
  &-1::after {
    background: url('./../../../assets/illustrations/1.png') no-repeat center
    center;

    background-size: contain;
  }
  &-2::after {
    background: url('./../../../assets/illustrations/3.png') no-repeat center
    center;

    background-size: contain;
  }
  &-3::after {
    background: url('./../../../assets/illustrations/close.png') no-repeat center
    center;
    background-size: contain;
  }
  &-4::after {
    background: url('./../../../assets/illustrations/4.png') no-repeat center
    center;
    background-size: contain;
  }
}

.cancelPlan {
  .star {
    font-family: PingFangSC, PingFang SC, sans-serif;
    color: red;
    font-size: 12px;
  }
  h4 {
    text-indent: 12px;
  }
  .btn {
    width: 80%;
    margin: 0 auto;
  }
}
.modifyPlan {
  margin: 5px;
  border: 1px solid #e6e6e6;
  border-radius: @border-radius-base;
  padding: 10px;
  h4 {
    margin: 5px 0;
    color: rgba(51, 51, 51);
  }
  .star {
    font-family: PingFangSC, PingFang SC, sans-serif;
    color: red;
    font-size: 12px;
  }
  .modifyPlan-films {
    //border: 1px solid #E6E6E6;
    width: 230px;
    li {
      display: flex;
      justify-content: start;
      align-items: center;
      border-bottom: 1px solid #e6e6e6;
      height: 40px;
      text-indent: 5px;
      &:last-child {
        text-align: right;
        text-indent: 150px;
      }
      span {
        text-indent: 15px;
        display: inline-block;
      }
    }
  }
}
</style>
